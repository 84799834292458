<template>
  <div class="offer-pane">
    <div class="offer-tip">
      实时追踪海内外公司职位信息，做中国人的海内外求职桥梁！提供最实时最全面的求职咨询！
    </div>
    <div class="offer-form">
      <div class="form_row">
        <el-input
          v-model="form.name"
          size="small"
          placeholder="搜索职位名称"
          prefix-icon="el-icon-search"
          @keyup.enter.native="handleCurrentChange(1)"
        >
          <el-button
            size="small"
            type="primary"
            slot="suffix"
            @click="handleCurrentChange(1)"
            >搜索</el-button
          >
        </el-input>
      </div>
      <div class="form_row">
        <div class="form-filter_row">
          <div class="col">
            <el-select
              @change="handleCurrentChange(1)"
              v-model="form.type"
              size="small"
              placeholder="求职类型"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="实习" value="0"></el-option>
              <el-option label="应届" value="1"></el-option>
              <el-option label="社招" value="2"></el-option>
            </el-select>
          </div>
          <div class="col">
            <el-select
              @change="handleCurrentChange(1)"
              v-model="form.country"
              size="small"
              placeholder="国家"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="中国" :value="1"></el-option>
              <el-option label="美国" :value="2"></el-option>
              <el-option label="加拿大" :value="3"></el-option>
              <el-option label="澳大利亚" :value="4"></el-option>
              <el-option label="英国" :value="5"></el-option>
              <el-option label="德国" :value="6"></el-option>
              <el-option label="法国" :value="7"></el-option>
              <el-option label="瑞士" :value="8"></el-option>
              <el-option label="爱尔兰" :value="9"></el-option>
              <el-option label="日本" :value="10"></el-option>
              <el-option label="荷兰" :value="11"></el-option>
              <el-option label="西班牙" :value="12"></el-option>
              <el-option label="俄罗斯" :value="13"></el-option>
            </el-select>
          </div>
          <div class="col">
            <el-select
              @change="handleCurrentChange(1)"
              v-model="form.position"
              size="small"
              placeholder="职位"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="SDE 码农" value="0"></el-option>
              <el-option label="DS 数据科学家" value="1"></el-option>
              <el-option label="DA 数据分析师" value="2"></el-option>
              <el-option label="PM 产品经理" value="3"></el-option>
              <el-option label="Operations 运营" value="4"></el-option>
            </el-select>
          </div>
          <div class="col">
            <el-select
              @change="handleCurrentChange(1)"
              v-model="form.scale"
              size="small"
              placeholder="公司规模"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="世界500强" value="1"></el-option>
              <el-option label="巨头公司 10k+人" value="2"></el-option>
              <el-option label="大型公司 1k-10k人" value="3"></el-option>
              <el-option label="小型公司 1000人以下" value="4"></el-option>
            </el-select>
          </div>
          <div class="col">
            <el-select
              v-model="form.salaryRange"
              size="small"
              placeholder="薪资要求"
              @change="handleCurrentChange(1)"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="$500以下（¥4K以下）" value="1"></el-option>
              <el-option label="$500-1K（¥4-7K）" value="2"></el-option>
              <el-option label="$1-3K（¥7-20K）" value="3"></el-option>
              <el-option label="$3-5K（¥20-35K）" value="4"></el-option>
              <el-option label="$5-7K（¥35-50K）" value="5"></el-option>
              <el-option label="$7-10K+（¥50-70K+）" value="6"></el-option>
              <el-option label="$10K+（¥70K+）" value="7"></el-option>
            </el-select>
          </div>
          <div class="reset-link" @click="reset">
            <span class="el-icon-refresh"></span><span>重置</span>
          </div>
        </div>
      </div>
    </div>
    <div class="offer-list">
      <div
        v-for="(item, index) in offerList"
        :key="index"
        class="offer-list-card"
      >
        <div class="list-card-left">
          <div class="list-card-title" @click="goWebsite(item.href)">
            <div class="list-job-name">{{ item.jobName }}</div>
            <span class="price">{{ item.jobSalary }}</span>
          </div>
          <div class="list-tags" v-if="item.jobTags && item.jobTags.length > 0">
            <span class="item" v-for="tag in item.jobTags.split(',')">{{
              tag
            }}</span>
          </div>
        </div>
        <div class="list-link" @click="goWebsite(item.href)">
          <img src="../../../assets/images/offer/e.png" alt="" /><span
            >官网直投</span
          >
        </div>
        <div class="list-card-info">
          <div class="company-cell" @click="goCompanyDetail(item.companyId)">
            <img :src="item.logo" alt="" />
            <div class="flex-col">
              <div class="c-title">
                {{ item.country == 1 ? item.name : item.nameEn }}
              </div>
              <div class="c-items">
                <div
                  v-if="item.intelligence"
                  size="small"
                  style="padding-right: 4px; padding-left: 2px; font-size: 12px"
                >
                  内部消息<span style="color: #e9e9ec">｜</span>
                </div>
                <div
                  size="small"
                  style="
                    padding-right: 1px;
                    font-size: 12px;
                    /*border-right: 1px solid #666;*/
                  "
                >
                  {{ item.scope }}<span style="color: #e9e9ec">｜</span>
                </div>
                <div
                  size="small"
                  style="padding-right: 0px; padding-left: 0px; font-size: 12px"
                >
                  {{
                    item.scale == 1
                      ? "世界500强"
                      : item.scale == 2
                      ? "巨头公司"
                      : item.scale == 3
                      ? "大型公司"
                      : item.scale == 4
                      ? "小型公司"
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="list-card-right">
          <div class="list-action">
            <div
              class="list-action-item list-action-flay"
              :class="{ active: item.deliver }"
              @click="toggleDelivered(item)"
            >
              <div class="ac-box-fly">
                <img
                  :src="
                    item.deliver
                      ? require('../../../assets/images/offer/telegram-w.png')
                      : require('../../../assets/images/offer/telegram.png')
                  "
                  alt=""
                />
              </div>
              <div class="ac-label">
                {{ item.deliver ? "已投递" : "标记已投递" }}
              </div>
            </div>
            <div
              class="list-action-item list-action-star"
              :class="{ active: item.collect }"
              @click="toggleStarred(item)"
            >
              <div class="ac-box-star">
                <span class="iconfont icon-toudi"></span>
              </div>
              <div class="ac-label">
                {{ item.collect ? "已加入" : "加入待投递" }}
              </div>
            </div>
          </div>
          <div class="list-time">
            {{ getTimeDifferenceDescription(formatTime(item.updateTime)) }}发布
          </div>
        </div>
      </div>
      <div class="empty-cell" v-if="offerList.length == 0">
        <img src="@/assets/images/offer/empty.png" alt="" />
        <div class="empty-cell-label">暂时没有相关内容～</div>
      </div>
    </div>
    <div class="offer-foot" v-if="offerList.length > 0">
      <el-pagination
        :current-page.sync="current"
        background
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChangePage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getOfferList,
  getNoLoginOfferList,
  jobCollectDelivered,
  deleteDelivered,
} from "@/service/offer";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      form: {},
      offerList: [],
      current: 1,
      total: 0,
      pageSize: 20,
      companyList: [],
    };
  },
  mounted() {
    this.handleCurrentChange(1);
  },
  methods: {
    goCompanyDetail(companyId) {
      this.$router.push(`/offer/companyDetail?id=${companyId}`);
    },
    reset() {
      this.form = {};
      this.handleCurrentChange(1);
    },
    goWebsite(url) {
      window.open(url);
    },
    onSubmit() {
      console.log("submit!");
    },
    toggleDelivered(item) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/login");
        return this.$message.info("请先登录");
      }
      this.$message.closeAll();
      if (item.deliver) {
        deleteDelivered(item.id).then((res) => {
          if (res.success) {
            this.handleCurrentChange(this.current);
            return this.$message.success("已取消投递！");
          }
        });
      } else {
        jobCollectDelivered(item.id, {
          state: 1,
        }).then((res) => {
          if (res.success) {
            this.handleCurrentChange(this.current);
            this.$message.success("标记为投递成功！");
          }
        });
      }
    },
    toggleStarred(item) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/login");
        return this.$message.info("请先登录");
      }
      this.$message.closeAll();
      if (item.deliver) {
        jobCollectDelivered(item.id, {
          state: 0,
        }).then((res) => {
          if (res.success) {
            this.handleCurrentChange(this.current);
          }
        });
      }
      if (item.collect) {
        deleteDelivered(item.id).then((res) => {
          if (res.success) {
            this.handleCurrentChange(this.current);
            return this.$message.success("已取消加入待投递！");
          }
        });
      } else {
        jobCollectDelivered(item.id, {
          state: 0,
        }).then((res) => {
          if (res.success) {
            this.handleCurrentChange(this.current);
            this.$message.success("加入待投递成功！");
          }
        });
      }
    },
    getJobs() {
      if (!this.userInfo || !this.userInfo.id) {
        getNoLoginOfferList({
          ...this.form,
          current: this.current,
          size: this.pageSize,
        }).then((res) => {
          this.offerList = res.result.records;
          this.total = res.result.total;
        });
      } else {
        getOfferList({
          ...this.form,
          current: this.current,
          size: this.pageSize,
        }).then((res) => {
          this.offerList = res.result.records;
          this.total = res.result.total;
        });
      }
    },
    handleCurrentChangePage(val) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.handleCurrentChange(val);
    },
    handleSizeChange(val) {
      this.size = val;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getJobs();
    },
    jumpCompanyDetail(companyId) {
      this.$router.push(`/offer/companyDetail?id=${companyId}`);
    },
    formatTime(utcTimeString) {
      const isoTimeString = utcTimeString + "Z";

      // 创建一个Date对象，它将根据浏览器的本地时区自动调整
      const date = new Date(isoTimeString);
      // 使用Intl.DateTimeFormat来格式化日期时间
      // 这里可以根据需要调整选项，例如日期和时间部分的展示方式
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // 使用24小时制，根据需要可以设置为true使用12小时制
      };

      // 格式化输出本地时区的日期时间
      const formattedDateTime = new Intl.DateTimeFormat(
        "default",
        options
      ).format(date);

      return formattedDateTime;
    },
    getTimeDifferenceDescription(timeString) {
      const givenTime = new Date(timeString);
      const currentTime = new Date();

      if (isNaN(givenTime)) {
        throw new Error("无效的时间字符串");
      }

      const timeDifference = currentTime - givenTime;
      const millisecondsInAnHour = 1000 * 60 * 60;
      const millisecondsInADay = millisecondsInAnHour * 24;
      const millisecondsInAWeek = millisecondsInADay * 7;

      if (timeDifference < millisecondsInAnHour) {
        return "1小时之前";
      } else if (timeDifference < millisecondsInADay) {
        const hours = Math.floor(timeDifference / millisecondsInAnHour);
        return `${hours}小时前`;
      } else if (timeDifference < millisecondsInAWeek) {
        const days = Math.floor(timeDifference / millisecondsInADay);
        return `${days}天前`;
      } else {
        return givenTime.toLocaleDateString();
      }
    },
  },
  computed: mapState(["userInfo"]),
};
</script>
<style scoped="scoped" lang="less">
.offer-list {
  min-height: 600px;
}
.offer-list-card {
  .c-items {
    margin-top: 14px;
  }
}
.company-logo img {
  border-radius: 40px;
}
.form_row ::v-deep .el-input__suffix {
  right: 0;
}
.reset-link {
  cursor: pointer;
  margin-right: 20px;
  color: #676767;
}
.list-card-left {
  width: 450px;
}
.list-job-name {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  vertical-align: top;
}
.list-card-info {
  width: 300px;
}
</style>
