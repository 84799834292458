<template>
  <div class="offer-pane">
    <div class="offer-tip">
      实时追踪海内外公司职位信息，做中国人的海内外求职桥梁！提供最实时最全面的求职咨询！
    </div>

    <div class="tab-bg">
      <el-tabs v-model="activeName">
        <el-tab-pane name="1">
          <div class="el-tab-tt" slot="label">
            已投递({{ quantityAlreadyDelivered }})
          </div>
        </el-tab-pane>
        <el-tab-pane name="2">
          <div class="el-tab-tt" slot="label">
            待投递({{ quantityToBeDelivered }})
          </div>
        </el-tab-pane>
      </el-tabs>

      <div class="offer-list">
        <div
          v-for="(item, index) in activeName == 1 ? isDriverList : noDriverList"
          :key="index"
          class="offer-list-card"
          :class="[!item.state ? 'disabled' : '']"
        >
          <div
            class="ac-tag-bar"
            v-if="item.state"
            :style="{ background: item.deliver ? '#0a7aff' : '#ffa00a' }"
          >
            {{ item.deliver ? "已投递" : "待投递" }}
          </div>
          <div class="list-card-left">
            <div class="list-card-title" @click="goWebsite(item)">
              <div class="list-job-name">{{ item.jobName }}</div>
              <span class="price">{{ item.jobSalary }}</span>
            </div>
            <div
              class="list-tags"
              v-if="item.jobTags && item.jobTags.length > 0"
            >
              <span
                class="item"
                v-if="index < 5"
                v-for="(tag, index) in item.jobTags.split(',')"
                >{{ tag }}</span
              >
            </div>
          </div>
          <div
            :class="['list-link', !item.state ? 'disabled' : '']"
            @click="goWebsite(item)"
          >
            <img src="../../../assets/images/offer/e.png" alt="" /><span
              >官网直投</span
            >
          </div>
          <div class="list-isExpired" v-if="!item.state">
            <img src="../../../assets/images/offer/over.png" alt="" />
          </div>
          <div class="list-card-info">
            <div class="company-cell" @click="goCompanyDetail(item.companyId)">
              <img :src="item.logo" alt="" />
              <div class="flex-col">
                <div class="c-title">
                  {{ item.country == 1 ? item.name : item.nameEn }}
                </div>
                <div class="c-items">
                  <div
                    v-if="item.intelligence"
                    size="small"
                    style="
                      padding-right: 4px;
                      padding-left: 2px;
                      font-size: 12px;
                    "
                  >
                    内部消息<span style="color: #e9e9ec">｜</span>
                  </div>
                  <div size="small" style="padding-right: 1px; font-size: 12px">
                    {{ item.scope }}<span style="color: #e9e9ec">｜</span>
                  </div>
                  <div
                    size="small"
                    style="
                      padding-right: 0px;
                      padding-left: 0px;
                      font-size: 12px;
                    "
                  >
                    {{
                      item.scale == 1
                        ? "世界500强"
                        : item.scale == 2
                        ? "巨头公司"
                        : item.scale == 3
                        ? "大型公司"
                        : item.scale == 4
                        ? "小型公司"
                        : ""
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="list-card-right">
            <div class="list-action">
              <div
                class="list-action-item list-action-flay"
                v-if="item.state && !item.deliver"
                :class="{ active: item.deliver }"
                @click="toggleDelivered(item)"
              >
                <div class="ac-box-fly" v-if="item.state">
                  <img
                    :src="
                      item.deliver
                        ? require('@/assets/images/offer/telegram-w.png')
                        : require('@/assets/images/offer/telegram.png')
                    "
                    alt=""
                  />
                </div>
                <div class="ac-label">
                  {{ item.deliver ? "取消投递" : "标记已投递" }}
                </div>
              </div>

              <div
                v-if="item.state && !item.collect"
                class="list-action-item list-action-star"
                :class="{ active: item.collect }"
                @click="toggleStarred(item)"
              >
                <div class="ac-box-star">
                  <span class="iconfont icon-toudi"></span>
                </div>
                <div class="ac-label">
                  {{ "加入待投递" }}
                </div>
              </div>
              <div
                class="list-action-item list-action-del"
                @click="toggledel(item)"
              >
                <div class="ac-box-del">
                  <span class="el-icon-delete-solid"></span>
                </div>
              </div>
            </div>
            <div class="list-time">
              {{ activeName == 1 ? "投递" : "加入" }}时间：{{
                formatTime(item.updateTime).split(" ")[0]
              }}
            </div>
          </div>
        </div>
        <div
          class="empty-cell"
          v-if="
            activeName == 1
              ? quantityAlreadyDelivered == 0
              : quantityToBeDelivered == 0
          "
        >
          <img src="@/assets/images/offer/empty.png" alt="" />
          <div class="empty-cell-label">暂时没有相关内容～</div>
        </div>
      </div>
    </div>
    <div class="offer-foot">
      <el-pagination
        :current-page.sync="current"
        background
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="
          activeName == 1 ? quantityAlreadyDelivered : quantityToBeDelivered
        "
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getMyJob,
  deleteOffer,
  jobCollectDelivered,
  deleteDelivered,
} from "@/service/offer";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      activeName: "1",
      isDriverList: [],
      noDriverList: [],
      current: 1,
      total: 0,
      pageSize: 10,
      quantityAlreadyDelivered: 0,
      quantityToBeDelivered: 0,
    };
  },
  mounted() {
    if (!this.userInfo || !this.userInfo.id) {
      this.$router.push("/login");
      return this.$message.info("请先登录");
    }
    this.getMyJobs();
  },
  computed: mapState(["userInfo"]),
  methods: {
    goCompanyDetail(companyId) {
      this.$router.push(`/offer/companyDetail?id=${companyId}`);
    },
    formatTime(utcTimeString) {
      const isoTimeString = utcTimeString + "Z";

      // 创建一个Date对象，它将根据浏览器的本地时区自动调整
      const date = new Date(isoTimeString);
      // 使用Intl.DateTimeFormat来格式化日期时间
      // 这里可以根据需要调整选项，例如日期和时间部分的展示方式
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // 使用24小时制，根据需要可以设置为true使用12小时制
      };

      // 格式化输出本地时区的日期时间
      const formattedDateTime = new Intl.DateTimeFormat(
        "default",
        options
      ).format(date);

      return formattedDateTime;
    },
    goWebsite(item) {
      this.$message.closeAll();
      if (item.state == 0) {
        return this.$message.warning("该职位已过期!");
      }
      window.open(item.href);
    },
    toggleStarred(item) {
      this.$message.closeAll();
      jobCollectDelivered(item.id, {
        state: 0,
      }).then((res) => {
        if (res.success) {
          this.handleCurrentChange(1);
          return this.$message.success("已加入待投递！");
        }
      });
    },
    toggleDelivered(item) {
      this.$message.closeAll();
      if (item.deliver) {
        jobCollectDelivered(item.id, {
          state: 0,
        }).then((res) => {
          if (res.success) {
            this.handleCurrentChange(1);
            return this.$message.success("已取消投递！");
          }
        });
      } else {
        jobCollectDelivered(item.id, {
          state: 1,
        }).then((res) => {
          if (res.success) {
            this.handleCurrentChange(1);
            this.$message.success("已标记为投递成功！");
          }
        });
      }
    },
    toggledel(item) {
      this.$confirm("确认删除此职位？", "提示", {
        customClass: "delClass",
      }).then(() => {
        deleteOffer(item.id).then((res) => {
          if (res.success) {
            this.$message.success("删除成功！");
            this.handleCurrentChange(1);
          }
        });
      });
    },

    handleSizeChange(val) {
      this.size = val;
      this.handleCurrentChange(1);
    },

    handleCurrentChange(val) {
      this.current = val;
      this.getMyJobs();
    },
    getMyJobs() {
      getMyJob({
        current: this.current,
        size: this.pageSize,
        state: 0,
      }).then((res) => {
        this.quantityAlreadyDelivered = res.result.quantityAlreadyDelivered;
        this.quantityToBeDelivered = res.result.quantityToBeDelivered;
        this.isDriverList = res.result.isDriverList;
        this.noDriverList = res.result.noDriverList;
      });
    },
  },
  activated() {
    if (!this.userInfo || !this.userInfo.id) {
      this.$router.push("/login");
      return this.$message.info("请先登录");
    }
  },
};
</script>
<style scoped="scoped">
.offer-list {
  padding-top: 15px;
  min-height: 400px;
}
.tab-bg {
  padding: 6px 18px;
  background: #f4f8f8;
  border-radius: 10px;
}
.disabled {
  background: #c9c9c9;
  cursor: not-allowed;
}
.list-card-left {
  width: 450px;
}
.list-isExpired {
  margin-left: 20px;
}
.offer-list-card {
  padding-left: 60px;
}
.list-link {
  margin-left: 50px;
}
.offer-list-card {
  .c-items {
    margin-top: 14px;
  }
}
</style>
