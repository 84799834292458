<template>
  <div class="offer-pane">
    <div class="offer-tip">
      实时追踪海内外公司职位信息，做中国人的海内外求职桥梁！提供最实时最全面的求职咨询！
    </div>
    <div class="offer-form">
      <div class="form_row">
        <el-input
          v-model="form.name"
          size="small"
          placeholder="搜索公司名称"
          prefix-icon="el-icon-search"
          @keyup.enter.native="handleCurrentChange(1)"
        >
          <el-button
            size="small"
            type="primary"
            slot="suffix"
            @click="handleCurrentChange(1)"
            >搜索</el-button
          >
        </el-input>
      </div>
      <div class="form_row">
        <el-row :gutter="30">
          <el-col :span="8">
            <el-select
              v-model="form.country"
              size="small"
              placeholder="国家"
              style="width: 100%"
              @change="handleCurrentChange(1)"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="海外" :value="-1"></el-option>
              <el-option label="中国" :value="1"></el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-select
              v-model="form.scale"
              size="small"
              placeholder="公司规模"
              style="width: 100%"
              @change="handleCurrentChange(1)"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="世界500强" :value="1"></el-option>
              <el-option label="巨头公司 10k+人" :value="2"></el-option>
              <el-option label="大型公司 1k-10k人" :value="3"></el-option>
              <el-option label="小型公司 1000人以下" :value="4"></el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <div class="checkbox-flex-ht">
              <div class="reset-link" @click="reset">
                <span class="el-icon-refresh"></span><span>重置</span>
              </div>
              <div class="checkbox-s">
                <el-checkbox
                  @change="handleCurrentChange(1)"
                  v-model="form.intern"
                  label="实习"
                ></el-checkbox>
                <el-checkbox
                  @change="handleCurrentChange(1)"
                  v-model="form.newGrad"
                  label="应届"
                ></el-checkbox>
                <el-checkbox
                  @change="handleCurrentChange(1)"
                  v-model="form.experience"
                  label="社招"
                ></el-checkbox>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="offer-list">
      <div class="offer-company">
        <div
          v-for="(company, index) in companyList"
          :key="index"
          class="offer-list-card"
          style="cursor: pointer"
          @click="jumpCompanyDetail(company.id)"
        >
          <div class="company-logo">
            <img :src="company.avatar" alt="公司Logo" />
          </div>
          <div class="flex-col">
            <div class="company-title">
              <!--              {{ company.name || company.nameEn }}-->
              {{ company.country == 1 ? company.name : company.nameEn }}
              {{ company.country == 1 ? company.nameEn : company.name }}
              <el-tag size="mini">
                {{
                  company.country == 1
                    ? "中国"
                    : company.country == 2
                    ? "美国"
                    : company.country == 3
                    ? "加拿大"
                    : company.country == 4
                    ? "澳大利亚"
                    : company.country == 5
                    ? "英国"
                    : company.country == 6
                    ? "德国"
                    : company.country == 7
                    ? "法国"
                    : company.country == 8
                    ? "瑞士"
                    : company.country == 9
                    ? "爱尔兰"
                    : company.country == 10
                    ? "日本"
                    : company.country == 11
                    ? "荷兰"
                    : company.country == 12
                    ? "西班牙"
                    : company.country == 13
                    ? "俄罗斯"
                    : ""
                }}</el-tag
              >
            </div>

            <div class="c-items">{{ company.description }}</div>
            <div class="c-items">
              <div
                  v-if="company.intelligence"
                  size="small"
                  style="padding-right: 4px; padding-left: 2px; font-size: 12px;"
              >
                内部消息<span style="color: #e9e9ec">｜</span>
              </div>
              <div
                size="small"
                style="
                  padding-right: 1px;
                  font-size: 12px;
                  /*border-right: 1px solid #666;*/
                "
              >
                {{ company.scope }} <span style="color: #e9e9ec"> ｜</span>
              </div>
              <div
                size="small"
                style="padding-right: 0px; padding-left: 0px; font-size: 12px"
              >
                {{
                  company.scale == 1
                    ? "世界500强"
                    : company.scale == 2
                    ? "巨头公司"
                    : company.scale == 3
                    ? "大型公司"
                    : company.scale == 4
                    ? "小型公司"
                    : ""
                }}
              </div>

            </div>
          </div>
          <div class="company-right">
            <div class="company-tags">
              <span :class="['item', company.intern == 1 ? 'item-blue' : '']"
                >Intern实习</span
              >
              <span :class="['item', company.newGrad == 1 ? 'item-blue' : '']"
                >New Grad 应届</span
              >
              <span
                :class="['item', company.experience == 1 ? 'item-blue' : '']"
                >Experience 社招</span
              >
            </div>
            <div class="list-tags" v-if="company.tagsName">
              <span
                v-if="index < 5"
                v-for="(listTag, index) in company.tagsName.split(',')"
                :key="index"
                class="item"
                >{{ listTag }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="empty-cell" v-if="companyList.length == 0">
        <img src="@/assets/images/offer/empty.png" alt="" />
        <div class="empty-cell-label">暂时没有相关内容～</div>
      </div>
    </div>
    <div class="offer-foot">
      <el-pagination
        :current-page.sync="current"
        background
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getCompanyList, jobCollectDelivered } from "@/service/offer";
export default {
  components: {},
  data() {
    return {
      form: {
        intern: "",
        newGrad: "",
        experience: "",
      },
      offerList: [],
      current: 1,
      total: 0,
      pageSize: 20,
      companyList: [],
    };
  },
  mounted() {
    this.handleCurrentChange(1);
  },
  methods: {
    reset() {
      this.form = {};
      this.handleCurrentChange(1);
    },
    goWebsite(url) {
      window.open(url);
    },
    onSubmit() {
      console.log("submit!");
    },
    toggleDelivered(item) {
      if (item.deliver) {
        return this.$message.info("已投递！");
      } else {
        jobCollectDelivered(item.id, {
          state: 1,
        }).then((res) => {
          if (res.success) {
            this.handleCurrentChange(1);
            this.$message.success("投递成功!");
          }
        });
      }
    },
    toggleStarred(item) {
      if (item.deliver) {
      } else {
        jobCollectDelivered(item.id, {
          state: 0,
        }).then((res) => {
          if (res.success) {
            this.handleCurrentChange(1);
            this.$message.success("收藏成功!");
          }
        });
      }
    },
    getJobs() {
      getCompanyList({
        ...this.form,
        intern:
          this.form.intern === true ? 1 : this.form.intern == false ? 0 : "",
        newGrad:
          this.form.newGrad === true ? 1 : this.form.newGrad == false ? 0 : "",
        experience:
          this.form.experience === true
            ? 1
            : this.form.experience == false
            ? 0
            : "",
        current: this.current,
        size: this.pageSize,
      }).then((res) => {
        this.companyList = res.result.records;
        this.total = res.result.total;
      });
    },
    handleSizeChange(val) {
      this.size = val;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.current = val;
      this.getJobs();
    },
    jumpCompanyDetail(companyId) {
      this.$router.push(`/offer/companyDetail?id=${companyId}`);
    },
  },
};
</script>
<style scoped="scoped" lang="less">
.offer-list {
  min-height: 600px;
}
.offer-list-card {
  .c-items {
    margin-top: 10px;
    color: #7f7f7f;
    font-size: 13px;
  }
}
.company-logo img {
  border-radius: 40px;
}
.reset-link {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #676767;
}
.form_row ::v-deep .el-input__suffix {
  right: 0;
}
.flex-col:hover .company-title {
  color: #75dab9;
}
</style>
