<template>
  <div class="offer-oversea">
    <div
      class="offer-banner"
      :style="{ backgroundImage: 'url(' + img + ')' }"
    ></div>
    <div class="container">
      <div class="offer-main">
        <div class="custom-tabs">
          <div class="tab-header">
            <div
              v-for="(tab, index) in tabs"
              :key="index"
              @click="selectTab(index)"
              :class="{ active: selectedIndex === index }"
              class="tab-button"
            >
              {{ tab.title }}
            </div>
          </div>
          <div class="tab-content">
            <div class="tab-pane" v-if="selectedIndex == 0">
              <job></job>
            </div>
            <div class="tab-pane" v-if="selectedIndex == 1">
              <company></company>
            </div>
            <div class="tab-pane" v-if="selectedIndex == 2">
              <myjob></myjob>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import company from "./components/company.vue";
import job from "./components/job.vue";
import myjob from "./components/myjob.vue";
import { mapState } from "vuex";
export default {
  components: {
    myjob,
    job,
    company,
  },
  data() {
    return {
      selectedIndex: 0,
      tabs: [
        {
          title: "职位速递",
        },
        {
          title: "世界公司",
        },
        {
          title: "我的职位",
        },
      ],
      img: require("../../assets/images/offer/offer-banner.jpg"),
    };
  },
  created() {},
  mounted() {
    // if (!this.userInfo || !this.userInfo.id) {
    //   this.$router.push("/login");
    //   return;
    // }
  },
  computed: mapState(["userInfo"]),
  methods: {
    selectTab(index) {
      this.selectedIndex = index;
      this.tabs.forEach((tab, i) => {
        tab.isActive = i === index;
      });
    },
  },
};
</script>
<style scoped="scoped">
@import url("../../assets/css/haioffer.css");
</style>
